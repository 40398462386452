import React from 'react'
import { Props, IDefaultProps } from './types'
import { Container, Row, Col, Alert } from 'reactstrap'
import Formsy from 'formsy-react'
import { SelectFormsy } from 'shared/components/formsy'
import { APagination } from 'shared/components/common'
import AllianceItem from 'modules/alliances/components/allianceitem/AllianceItem'

interface IState {
  open: boolean
  selectedState: boolean
  selectedCity: boolean
  buttonDisabled: boolean
  totalCount: number
  filterState: number
  filterCity: number
  filterService: number
  pages: number
  errorMessage: string
  arrayLength: number
  offset: number
}

class Alliance extends React.PureComponent<Props, IState> {
  static defaultProps: IDefaultProps = {
    states: [],
    cities: [],
    categories: [],
    alliances: {
      count: 0,
      items: 0,
      results: []
    }
  }

  state: IState = {
    open: false,
    selectedState: false,
    selectedCity: false,
    buttonDisabled: false,
    totalCount: 0,
    filterState: 0,
    filterCity: 0,
    filterService: 0,
    pages: 0,
    errorMessage: '',
    arrayLength: 0,
    offset: 1
  }

  totalCount: any

  constructor(props: Props) {
    super(props)
    this.handleStateChange = this.handleStateChange.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    this.handleCity = this.handleCity.bind(this)
    this.handlePage = this.handlePage.bind(this)
    this.state = {
      open: false,
      selectedState: false,
      selectedCity: false,
      buttonDisabled: false,
      totalCount: 0,
      filterState: 0,
      filterCity: 0,
      filterService: 0,
      pages: 0,
      errorMessage: '',
      arrayLength: 0,
      offset: 1
    }
  }

  componentDidMount() {
    this.props.getStates()
    this.props.getCategories()
    this.props
      .getAlliances(0)
      .then(R => {
        if (R.count < 1) {
          this.setState({ errorMessage: 'No hay resultados.' })
        } else {
          this.setState({ totalCount: R.count, pages: Math.ceil(R.count / 4), offset: 1 })
          if (Math.ceil(R.count / 4) >= 5) {
            this.setState({ arrayLength: 5 })
          } else {
            this.setState({ arrayLength: Math.ceil(R.count / 4) })
          }
        }
      })
      .catch(err => {
        this.setState({ errorMessage: err.message })
      })
  }

  handleStateChange(val: any) {
    let id = val
    if (id) {
      id = parseInt(val, 10)
    }
    this.setState({ selectedState: true })
    this.props.getCities(id)
  }

  handleCategoryChange() {
    this.setState({ buttonDisabled: true })
  }

  handleCity() {
    this.setState({ buttonDisabled: true })
  }

  onSubmit = (model: any) => {
    this.setState({
      filterState: model.filterState,
      filterCity: model.filterCity,
      filterService: model.filterService,
      totalCount: 0,
      pages: 0,
      offset: 0
    })

    this.props
      .getAlliances(model.filterState, model.filterCity, 0, model.filterService)
      .then(R => {
        if (R.count < 1) {
          this.setState({ errorMessage: 'No hay resultados.' })
        } else {
          this.setState({ totalCount: R.count, pages: Math.ceil(R.count / 4), offset: this.state.offset + 1 })
          if (Math.ceil(R.count / 4) >= 5) {
            this.setState({ arrayLength: 5 })
          } else {
            this.setState({ arrayLength: Math.ceil(R.count / 4) })
          }
        }
      })
      .catch(err => {
        this.setState({ errorMessage: err.message })
      })
  }

  handlePage(numero?: number) {
    this.props.getAlliances(this.state.filterState, this.state.filterCity, numero, this.state.filterService)
    window.scrollTo(31, 279)
  }

  render(): React.ReactNode {
    const { states, cities, categories, alliances } = this.props
    const { offset, selectedState, buttonDisabled, totalCount, pages, errorMessage, arrayLength } = this.state
    return (
      <Container>
        <Container className="pad">
          <Formsy onValidSubmit={this.onSubmit}>
            <Row>
              <Col sm={3}>
                <SelectFormsy
                  name="filterState"
                  label="Estado"
                  value=""
                  onChange={this.handleStateChange}
                  selectProps={{ className: 'custom-select' }}
                  labelClassName="h6"
                >
                  <option value="">Selecciona el estado</option>
                  {(states &&
                    states.map(t => (
                      <option value={t.id} key={t.id}>
                        {t.name}
                      </option>
                    ))) ||
                    null}
                </SelectFormsy>
              </Col>
              <Col sm={3}>
                <SelectFormsy
                  name="filterCity"
                  label="Ciudad"
                  value=""
                  onChange={this.handleCity}
                  selectProps={{ className: 'custom-select', disabled: !selectedState }}
                  labelClassName="h6"
                >
                  <option value="">Selecciona la ciudad</option>
                  {(cities &&
                    cities.map(t => (
                      <option value={t.id} key={t.id}>
                        {t.name}
                      </option>
                    ))) ||
                    null}
                </SelectFormsy>
              </Col>
              <Col sm={3}>
                <SelectFormsy
                  name="filterService"
                  label="Servicio"
                  value=""
                  onChange={this.handleCategoryChange}
                  selectProps={{ className: 'custom-select' }}
                  labelClassName="h6"
                >
                  <option value="">Selecciona el servicio</option>
                  {(categories &&
                    categories.map(t => (
                      <option value={t.id} key={t.id}>
                        {t.name}
                      </option>
                    ))) ||
                    null}
                </SelectFormsy>
              </Col>
              <Col>
                <Col>
                  <label className="big-gray h6 invisible" htmlFor="btnm">
                    Servicio
                  </label>
                  <button id="btnm" disabled={!buttonDisabled} type="submit" className="btn-med">
                    Buscar
                  </button>
                </Col>
              </Col>
            </Row>
          </Formsy>
        </Container>
        <Container>
          <AllianceItem alliances={alliances} />
          {totalCount >= 1 ? (
            <APagination handlePage={this.handlePage} offset={offset} arrayLength={arrayLength} totalItems={totalCount} pages={pages} />
          ) : (
            <Row>
              <Col>
                <Alert color="info" className="w-75 mx-auto mt-5">
                  {errorMessage}
                </Alert>
              </Col>
            </Row>
          )}
        </Container>
      </Container>
    )
  }
}
export default Alliance
