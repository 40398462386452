import React, { Component } from 'react'
import classes from './ALoader.module.css'
import { ALoaderProps } from './types'
import classNames from 'classnames'

export class ALoader extends Component<ALoaderProps> {
  render() {
    return (
      <div className={classNames(classes.spinner, this.props.className)}>
        <div className={classes.bounce1} />
        <div className={classes.bounce2} />
        <div className={classes.bounce3} />
      </div>
    )
  }
}

export default ALoader
