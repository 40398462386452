import React, { Component } from 'react'
import Formsy from 'formsy-react'
import { Row, Col, Alert } from 'reactstrap'
import { InputFormsy } from 'shared/components/formsy'
import { auth } from 'services/authservice'
import { navigate } from 'gatsby'
import { PASSWORD } from 'core/utils'

class Recoberpass extends Component {
  form: any
  state = {
    step2: false,
    email: '',
    token: '',
    error: '',
    canSubmit: false
  }
  onSubmit = (model: any) => {
    this.setState({ canSubmit: false })
    if (model.email) {
      auth
        .recoberPass(model.email)
        .then((R: any) => {
          this.setState({ step2: true, email: model.email, token: R.recover_token, error: '' })
          this.form.reset()
        })
        .catch(() => {
          this.setState({ error: 'El email no se encuentra registrado' })
        })
    } else {
      auth
        .restorePass(this.state.email, model.code, this.state.token, model.pass)
        .then(() => {
          this.setState({ error: '' })
          navigate('/iniciarsesion')
        })
        .catch(() => {
          this.setState({ error: 'Codigo de verificacion incorrecto' })
        })
    }
  }
  enableButton = () => {
    this.setState({ canSubmit: true })
  }

  render() {
    const { step2, error, canSubmit } = this.state
    return (
      <Formsy
        ref={(node: any) => {
          this.form = node
        }}
        onValid={this.enableButton}
        onValidSubmit={this.onSubmit}
        className="w-50 mx-auto mt-5"
      >
        {error ? (
          <Alert color="danger" className="d-inline-block">
            {error}
          </Alert>
        ) : null}
        {step2 ? (
          <Row>
            <Col sm={12}>
              <Alert color="success">Se envio un codigo de recuperacion a su correo</Alert>
              <InputFormsy
                name="pass"
                label="Nueva contraseña"
                inputProps={{ placeholder: 'Escribe tu nueva Contraseña', type: 'password' }}
                validations={{
                  matchRegexp: PASSWORD
                }}
                validationErrors={{
                  matchRegexp: 'La contraseña introducida no es segura. Debe tener al menos un número y una longitud de 8 caracteres'
                }}
                required
              />
              <InputFormsy
                type="password"
                name="repeated_password"
                label="Repetir la nueva contraseña"
                inputProps={{ placeholder: 'Escribe de nuevo la Contraseña', type: 'password' }}
                required
                validations={{
                  matchRegexp: PASSWORD,
                  equalsField: 'pass'
                }}
                validationErrors={{
                  equalsField: 'Las contraseñas no coinciden',
                  matchRegexp: 'La contraseña introducida no es segura. Debe tener al menos un número y una longitud de 8 caracteres'
                }}
              />
              <InputFormsy
                name="code"
                label="Codigo de verificacion"
                inputProps={{
                  placeholder: 'Ingresa el codigo de recuperacion',
                  type: 'number'
                }}
                required
              />
              <div className="form-group">
                <button type="submit" className="btn-big mt-3" disabled={!canSubmit}>
                  Recuperar contraseña
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={12}>
              <InputFormsy
                name="email"
                label="Correo electronico"
                inputProps={{
                  placeholder: 'Escribe tu direccion de correo electronico',
                  type: 'email'
                }}
                required
                validations={{
                  isEmail: true
                }}
                validationErrors={{
                  isEmail: 'Formato de email no valido'
                }}
              />
              <div className="form-group">
                <button type="submit" className="btn-big mt-3" disabled={!canSubmit}>
                  Enviar codigo
                </button>
              </div>
            </Col>
          </Row>
        )}
      </Formsy>
    )
  }
}

export default Recoberpass
