import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import {
  TravelThunkDispatch,
  fetchSpecialTickets,
  setTravelData,
  getSpecialTickets,
  getTravel,
  fetchTerminals,
  getTerminals,
  removeTravelData
} from 'store/travel'

import IndexLayout from './IndexLayout'
import { IndexLayoutDispatchProps } from './types'
import { Travel } from 'modules/travel/models'

export const mapState = (state: ApplicationState) => {
  return {
    terminals: getTerminals(state),
    special_tickets: getSpecialTickets(state),
    travel: getTravel(state),
    user: state.user
  }
}

export const mapDispatch = (dispatch: TravelThunkDispatch): IndexLayoutDispatchProps => ({
  getTerminals: () => dispatch(fetchTerminals()),
  getTickets: () => dispatch(fetchSpecialTickets()),
  setTravelData: (travel: Travel) => dispatch(setTravelData(travel)),
  removeTravelData: () => dispatch(removeTravelData())
})

export default connect(mapState, mapDispatch)(IndexLayout)
