import * as React from 'react'
import { Card, Row, Col } from 'reactstrap'
import Container from 'reactstrap/lib/Container'
import { Props, IDefaultProps } from './types'
import CardTitle from 'reactstrap/lib/CardTitle'
import CardText from 'reactstrap/lib/CardText'
import descuento from 'img/descuento.svg'
import { Config } from 'core'
import notFound from 'img/img404.png'
interface IState {
  open: boolean
}

class AllianceItem extends React.PureComponent<Props, IState> {
  static defaultProps: IDefaultProps = {
    alliances: {
      count: 0,
      items: 0,
      results: []
    }
  }
  constructor(props: Props) {
    super(props)
  }
  render(): React.ReactNode {
    const { alliances } = this.props
    const results = alliances ? alliances.results : []
    return (
      <Container fluid>
        {results.length
          ? results.map((a, index) => (
              <Card style={{ border: 'none' }} key={index} className="mt-3">
                <Row className="no-gutters">
                  <Col className="col-3">
                    <img
                      className="img-thumbnail"
                      src={`${Config.imgPath}/${a.img_file}`}
                      onError={ev => {
                        ev.currentTarget.src = notFound
                      }}
                    />
                  </Col>
                  <Col className="auto">
                    <div className="card-block px-2">
                      <CardTitle>{a.name}</CardTitle>
                      <CardText>{a.description}</CardText>
                      {(a.services &&
                        // tslint:disable-next-line: no-shadowed-variable
                        a.services.map((b: { name: React.ReactNode }, index: number) => (
                          <h6 key={index} className="big-pink-op">
                            <img src={descuento} /> <b>{b.name}</b>{' '}
                          </h6>
                        ))) ||
                        null}
                      <a href={a.link} target="_blank">
                        {a.link}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card>
            ))
          : null}
      </Container>
    )
  }
}
export default AllianceItem
