import React, { Component } from 'react'
import { Container, Row, Col, Alert, Spinner } from 'reactstrap'
import Formsy from 'formsy-react'
import prevImg from 'img/vectores/flecha-izq.svg'
import nextImg from 'img/vectores/flecha-der.svg'
import offersImg from 'img/ofertas-correo.png'
import { Slide } from 'shared/components/common/ACarousel/types'
import { AButton, ACarousel } from 'shared/components/common'
import { IndexLayoutProps, IndexLayoutState } from './types'
import { Travel } from 'modules/travel/models'
import { navigate } from 'gatsby'
import { ApiErrors } from 'core/services/ApiService/types'
import { TrackingSearch } from 'modules/parcels/components'
import { InputFormsy } from 'shared/components/formsy'
import ALoader from 'shared/components/ALoader/ALoader'
import { ApiService } from 'core/services'
import './IndexLayout.css'
import { CaruselService } from 'services'

import { FacebookPixel, setupPixel } from 'core/utils'

let ReactPixel: FacebookPixel

const controlImgs: any = {
  prev: prevImg,
  next: nextImg
}

class IndexLayout extends Component<IndexLayoutProps, IndexLayoutState> {
  subscribeForm = React.createRef()
  state: IndexLayoutState = {
    travelDataFetched: false,
    slides: [],
    slidDataFetched: false
  }
  slides: Slide[] = []
  constructor(props: IndexLayoutProps) {
    super(props)
    this.onTravelSubmit = this.onTravelSubmit.bind(this)
    CaruselService.getImg()
      .then(data => {
        const DataImg: any[] = data[0]
        DataImg.forEach(slideImg => {
          const slidenew: Slide = {
            src: slideImg.Slide
          }
          this.slides.push(slidenew)
        })
        this.setState({ slides: this.slides, slidDataFetched: true })
      })
      .catch(err => {
        console.error(err)
      })
  }

  componentDidMount() {
    const { getTerminals, getTickets } = this.props
    Promise.all([getTerminals(), getTickets()])
      .then(() => {
        this.setState({ travelDataFetched: true })
      })
      .catch(err => {
        if (err && err.status === ApiErrors.HANDLED_ERROR) {
          return this.setState({ travelError: err.message, travelDataFetched: true })
        }
        console.error('Error on getting terminals and tickets', err)
      })
    ReactPixel = setupPixel()
  }

  sliderControl = (direction: string) => (fn: any) => (
    <a
      className={`carousel-control-${direction}`}
      onClick={e => {
        e.preventDefault()
        fn()
      }}
      role="AButton"
      tabIndex={0}
    >
      <img src={controlImgs[direction]} />
      <span className="sr-only">{direction}</span>
    </a>
  )

  onTravelSubmit(travel: Travel) {
    const { setTravelData, removeTravelData } = this.props
    removeTravelData()
    travel.step = 0
    travel.passengers = Travel.createPassengers(travel)
    setTravelData(travel)
    navigate('viaje')
  }

  onTrackingSearch = (code: string) => {
    navigate('rastreo', {
      state: { code }
    })
  }

  onSubscribeSubmit = (model: any) => {
    const { email } = model
    const newState: any = { subscribing: false }
    this.setState({ subscribing: true, subscribeError: '', subscribeSuccess: '' })
    ApiService.post<any, { email: string }>('customers/subscription', { email })
      .then(() => {
        newState.subscribeSuccess = 'A partir de este momento le llegarán promociones exclusivas a su correo.'
        ReactPixel?.track('Lead', { email })
        this.setState(newState)
      })
      .catch(err => {
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.subscribeError = err.message
        } else {
          newState.subscribeError = `Error al subscribir. Intente de nuevo más tarde.`
        }
        this.setState(newState)
      })
  }

  onSubscribeValidForm = () => {
    this.setState({ canSubscribe: true })
  }

  onSubscribeInvalidForm = () => {
    this.setState({ canSubscribe: false })
  }

  render() {
    // const { terminals, special_tickets } = this.props
    const {
      // travelDataFetched,
      // travelError,
      canSubscribe,
      subscribeError,
      subscribeSuccess,
      subscribing,
      slides,
      slidDataFetched
    } = this.state
    return (
      <>
        <Row className="RowSlideOverflow">
          <Col xs={12} md={12} lg={6} xl={6} sm={12}>
            <Container className="pad">
              <TrackingSearch onConfirm={this.onTrackingSearch} />
            </Container>
          </Col>
          <Col style={{ paddingTop: '20px' }}>
            {slidDataFetched ? (
              <ACarousel items={slides} prevButtonRender={this.sliderControl('prev')} nextButtonRender={this.sliderControl('next')} />
            ) : (
              <Spinner color="danger" />
            )}
          </Col>
        </Row>

        {/* <Container className={'pestanas-index pad'}>
          <hr className="divider" />
        </Container> */}
        {/* <PaymentBanner /> */}

        <Container>
          <Formsy
            ref={this.subscribeForm}
            onValidSubmit={this.onSubscribeSubmit}
            onValid={this.onSubscribeValidForm}
            onInvalid={this.onSubscribeInvalidForm}
          >
            <Row>
              <Col sm={9} xs={8}>
                <h2 className="big-blue">Recibe ofertas y promociones exclusivas en tu correo</h2>
                <br />
                <p>Quiero suscribirme al boletín de All Abordo para recibir promociones exclusivas en mi correo electrónico.</p>
                <br />
              </Col>
              <Col sm={3} xs={4}>
                <img src={offersImg} />
              </Col>
              <Col sm={8} xs={12}>
                <InputFormsy
                  name="email"
                  inputProps={{
                    placeholder: 'Correo electrónico',
                    disabled: subscribing
                  }}
                  validations={{ isEmail: true }}
                  validationErrors={{ isEmail: ' El texto introducido no es un correo válido' }}
                  required
                />
              </Col>
              <Col sm={4}>
                <AButton type="submit" variant="med" disabled={subscribing || !canSubscribe}>
                  Suscribirme
                </AButton>
              </Col>
              <Col sm={12} className="text-center">
                {subscribing ? <ALoader className="mx-auto" /> : null}
                {subscribeError ? <Alert color="danger">{subscribeError}</Alert> : null}
                {subscribeSuccess ? <Alert color="success">{subscribeSuccess}</Alert> : null}
              </Col>
            </Row>
          </Formsy>
        </Container>
      </>
    )
  }
}

export default IndexLayout
