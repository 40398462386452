import Alliance from './Alliance'
import { connect } from 'react-redux'
import { ApplicationState } from 'store'
import { getStates, StateThunkDispatch } from 'store/states'
import { getCities, CityThunkDispatch } from 'store/cities'
import { getAlliances, AllianceThunkDispatch, getCategories } from 'store/alliances'

export const mapState = (state: ApplicationState) => {
  return {
    states: state.states.states,
    cities: state.cities.cities,
    categories: state.alliances.allianceCategory,
    alliances: state.alliances.alliances
  }
}

type AllianceDispatch = StateThunkDispatch & CityThunkDispatch & AllianceThunkDispatch

export const mapDispatch = (dispatch: AllianceDispatch) => ({
  getStates: () => dispatch(getStates()),
  getCities: (state_id: number) => dispatch(getCities(state_id)),
  getAlliances: (state_id?: number, city_id?: number, page?: number, typeService?: number) =>
    dispatch(getAlliances(state_id, city_id, page, typeService)),
  getCategories: () => dispatch(getCategories())
})

// @ts-ignore
export default connect(mapState, mapDispatch)(Alliance)
